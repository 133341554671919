import _ from 'lodash';
import React from 'react';
import PageHeader from '../PageHeader';
import styles from './onlineTherapy.module.scss';
import data from '../../data/onlinePsychotherapy';

const OnlineTherapy = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1499182110/treatmentPhilosophy.jpg';

  const contraindications = _.map(data.contraindications, (item, index) => {
    return <li key={index}>{item}</li>;
  });

  const recommendations = _.map(data.recommendations, (item, index) => {
    return <li key={index} dangerouslySetInnerHTML={{ __html: item }} />;
  });

  const requirements = _.map(data.requirements, (item, index) => {
    return <li key={index}>{item}</li>;
  });

  const howDoesItWork = _.map(data.howDoesItWork, (item, index) => {
    return <li key={index}>{item}</li>;
  });

  const sources = _.map(data.sources, (item, index) => {
    return (
      <li
        className={styles.numberListItem}
        key={index}
        dangerouslySetInnerHTML={{ __html: item }}
      />
    );
  });

  return (
    <main>
      <PageHeader pageCategory="About" pageName="Online Psychotherapy" headerImage={background} />
      <div className={styles.textContainer}>
        <img
          className={styles.image}
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1585195510/online-psychotherapy.jpg"
          alt="Online psychotherapy"
        />
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>
          Online therapy is NOT recommended if you struggle with the following conditions:
        </h2>
        <ul>{contraindications}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>Online therapy is recommended when:</h2>
        <ul>{recommendations}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>What do you need?</h2>
        <ul>{requirements}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>How does online therapy work?</h2>
        <ul>{howDoesItWork}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy}>Call us at 801-427-1054 to schedule your appointment.</p>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>Sources</h2>
        <ol type="1">{sources}</ol>
      </div>
    </main>
  );
};

export default OnlineTherapy;
