const onlinePsychotherapy = {
  intro:
    'After scheduling your appointment with your therapist, the day of your counseling session may turn out to be a' +
    ' difficult one. You may not be able to attend your session for a variety of reasons. You might think, “I wish' +
    ' my therapist  could come to my home and help me.” No worries, we have you&nbsp;covered.' +
    '<br><br>Our therapists at Mindset Family Therapy are available to provide you with online counseling for your' +
    ' psychological, emotional, and relationship needs. At Mindset Family Therapy, we utilize a time-tested, state of the art, and HIPPA compliant online program, Simple Practice. Simple Practice is an encrypted and secure teleconference program to support you on those days that you are unable to come into our office.' +
    '<br><br>You may actually prefer to do online therapy because it can be more convenient due to a busy schedule,' +
    ' family circumstances, location, or travel conditions. Of course, online therapy is not meant to always be a' +
    ' substitute for face-to-face therapy. However, it is an additional service you can count on at Mindset Family Therapy, especially when the other option is not having therapy at all.' +
    '<br><br>It is reported that over 90% of those doing online therapy feel they have been helped.<sup>1</sup> A' +
    ' study<sup>2</sup> by the' +
    ' University of Zurich reported that online psychotherapy (telehealth services) is an effective supplement to' +
    ' therapeutic care. Other research<sup>3</sup> has found that many individuals may not realize their need for' +
    ' intensive treatment, and online therapy can be their first step towards seeking more services.' +
    '<br><br>OCD treatment can be a challenge because of lack of OCD evidence-based interventions utilized by many' +
    ' mental health providers. You can choose an experienced clinic that provides research-based OCD therapy and offers appropriate care online.' +
    '<br><br>Location and inconvenience due to the illness itself can be a barrier for those seeking the right treatment.' +
    ' Online OCD psychotherapy can be the best option for some individuals. Studies<sup>4</sup> are also showing' +
    ' that' +
    ' online therapy for individuals struggling with OCD can be an additional and effective way to help clients as they engage in the therapeutic process. However, online therapy is not for everyone.',
  contraindications: [
    'Currently experiencing thoughts of harming yourself or others.',
    'Exhibiting psychotic symptoms such as hallucinations, delusions, and thought disorganization.',
    'You deem your condition to be too critical and need physical, mental, and emotional support in person or hospitalization.',
  ],
  recommendations: [
    'Your physical health may limit your ability to attend face-to-face sessions.',
    'Your psychological condition may not allow you to travel (e.g., anxiety, phobias, OCD).',
    'Your location limits your ability to attend in-person sessions because you live far from our office.',
    'You are not able to find an experienced mental health provider locally who can offer evidence-based treatments' +
      ' (e.g., CBT, ERP and ACT for OCD).',
    'You are a current client and an unexpected situation impedes your ability to attend a session at our office.',
    'Your schedule does not allow you to come in person, but you can attend online sessions.',
    'Your family circumstances prevent you from attending in-person sessions (e.g., pregnant moms, parents with' +
      ' preschoolers, or sick family members).',
    '<strong>You live in Utah or outside the United States.</strong>',
  ],
  requirements: [
    'A computer, laptop, iPad, or a smart phone with a working camera.',
    'A reliable high-speed internet connection.',
    'A quiet and isolated room to ensure privacy and prevent other people from distracting and interrupting your' +
      ' session.',
    'The ability to download the program.',
  ],
  howDoesItWork: [
    'You will complete electronic intake forms just as you would if you were to attend sessions in person.',
    'After scheduling your appointment and completing all the necessary paperwork, you’ll receive a message with' +
      ' simple instructions to connect to our program.',
    'On the day of your appointment, your therapist will send you a reminder to connect.',
    'Your therapist will call you and then proceed with your online session.',
    'You always have the option to personally come to our office. Let your therapist know if that is what you wish' +
      ' to do.',
  ],
  sources: [
    'ABC’s of “Internet Therapy.” Metanoia.org <a target="_blank"' +
      ' href="https://metanoia.org/imhs/alliance.htm">https://metanoia.org/imhs/alliance.htm</a>',
    'University of Zurich. (2013). Psychotherapy via internet as good as if not better than face-to-face' +
      ' consultations. <i>ScienceDaily</i>. Retrieved March 20, 2020 from' +
      '  <a' +
      ' target="_blank"' +
      ' href="https://sciencedaily.com/releases/2013/07/130730091255.htm">https://sciencedaily.com/releases/2013/07/130730091255.htm</a>',
    'Novotney, A., A Growing Wave of Online Therapy. <i>American Psychological Association</i>, February 2017, Vol. 48,' +
      ' No.&nbsp;2<br>' +
      '(<a' +
      ' target="_blank"' +
      ' href="https://www.apa.org/monitor/2017/02/online-therapy">https://www.apa.org/monitor/2017/02/online-therapy</a>).',
    'Andersson, E., Ljótsson, B., Hedman, E., Kaldo, V., Paxling, B., Andersson, G., Lindefors, N., & Rück, C.' +
      ' (2011). Internet-based cognitive behavior therapy for obsessive compulsive disorder: a pilot study. <i>BMC' +
      ' psychiatry, 11</i>, 125. <a' +
      ' target="_blank" href="https://doi.org/10.1186/1471-244X-11-125">https://doi.org/10.1186/1471-244X-11-125</a>' +
      ' <br><br>Matthews, A.J., Maunder, R., Scanlan, J. D., Kirkby K.C. (2017). Online computer-aided vicarious' +
      ' exposure for OCD symptoms: A pilot study. <i>Journal of Behavior Therapy and Experimental Psychiatry</i>, 54.' +
      ' <a target="_blank"' +
      ' href="https://www.ncbi.nlm.nih.gov/pubmed/27308724">https://www.ncbi.nlm.nih.gov/pubmed/27308724</a><br><br>Patel S.R, Wheaton, M.G, Andersson E., Rück C., Schmidt, A.B., La Lima, C.N., Galfavy, H., Pascucci, O., Myers,' +
      ' R.W., Dixon, L.B., & Simpson, H.B. (2018). Acceptability, Feasibility, and Effectiveness of Internet-Based' +
      ' Cognitive-Behavioral Therapy for Obsessive-Compulsive Disorder in New York. <i>Behavior Therapy</i>, 49, 4.' +
      ' <a target="_blank"' +
      ' href="https://www.ncbi.nlm.nih.gov/pubmed/29937263">https://www.ncbi.nlm.nih.gov/pubmed/29937263</a><br><br>Rees, C. S., Anderson, R. A., Kane, R. T., & Finlay-Jones, A. L. (2016). Online Obsessive-Compulsive Disorder Treatment: Preliminary Results of the "OCD? Not Me!" Self-Guided Internet-Based Cognitive Behavioral Therapy Program for Young People. <i>JMIR mental health, 3</i>(3), e29. <a target="_blank" href="https://doi.org/10.2196/mental.5363">https://doi.org/10.2196/mental.5363</a><br><br>Storch, E. A., Caporino, N.E., Morgan, J. R., Adam B., Lewin, A. B., Rojas, A., Brauer, L., Larson, M. J., & Murphy, T. K. (2011). Preliminary investigation of web-camera delivered cognitive-behavioral therapy for youth with obsessive-compulsive disorder. <i>Psychiatry Research</i>, 189, 3. <a target="_blank" href="https://www.ncbi.nlm.nih.gov/pubmed/21684018">https://www.ncbi.nlm.nih.gov/pubmed/21684018</a><br><br>Wootton, B. M., Johnston, L., Schwencke, G.,  Zou, J., Lu, S. Lorian, C., Mullin, A., Spence, J., & Terides, M. <i>Can Obsessive Compulsive Disorder be Treated Online?</i> [PowerPoint presentation]. Retrieved from  <a target="_blank" href="https://adaa.org/sites/default/files/Wooten%20441.pdf">https://adaa.org/sites/default/files/Wooten%20441.pdf</a>',
  ],
};

export default onlinePsychotherapy;
