import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import OnlineTherapy from '../components/OnlineTherapy';

export default () => (
  <React.Fragment>
    <SEO
      title="Online Psychotherapy | Mindset Family Therapy in Provo, UT"
      description="Our therapists at Mindset Family Therapy are available to provide you with online counseling for your psychological, emotional, and relationship needs. At Mindset Family Therapy, we utilize a time-tested, state of the art, and HIPPA compliant online program."
      pathname="/online-psychotherapy"
    />
    <Layout>
      <OnlineTherapy />
    </Layout>
  </React.Fragment>
);
